import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/UI/seo'
import siteinfos from '../settings/siteinfos.json'
import Container from '../components/UI/Container'
import Section from '../components/UI/Section'
const NotFoundPage = () => (
  <Layout>
    <SEO title={siteinfos.page404.title} description={siteinfos.page404.text} />
    <Section>
      <Container textAlign='center'>
        <div>
          <h1>{siteinfos.page404.title}</h1>
          <p>{siteinfos.page404.text}</p>
        </div>
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
